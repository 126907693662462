import React from "react";
import Base from "../NavBar/Base";

const VanillaJS = () => {
  return (
    <div>
      <Base />
      <span className="p-2"></span>
      <div className="container container-center">
        <ol reversed>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">Valyrian Language</h1>
              <small>December, 2020</small>
              <p>
                An app made using <strong>VanillaJS</strong>. Want to convert
                English Language into Valyrian Language? Be sure to check this
                out.
              </p>
              <button className="btn btn-warning">
                <a
                  className="link link-primary"
                  href="./projectFour/valyrian.html"
                >
                  Live Project
                </a>
              </button>
              <span className="p-2"></span>
              <button className="btn btn-danger">
                <a
                  className="link link-secondary"
                  href="https://github.com/varunraj30/valyrian-translation"
                >
                  View Source
                </a>
              </button>
            </div>
          </li>
          <span className="p-1"></span>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">Minion Language</h1>
              <small>December, 2020</small>
              <p>
                An app made using <strong>VanillaJS</strong>. Want to convert
                English Language into Minion Language? Be sure to check this
                out.
              </p>
              <button className="btn btn-warning">
                <a
                  className="link link-primary"
                  href="./projectThree/minions.html"
                >
                  Live Project
                </a>
              </button>
              <span className="p-2"></span>
              <button className="btn btn-danger">
                <a
                  className="link link-secondary"
                  href="https://github.com/varunraj30/Minion-Translation"
                >
                  View Source
                </a>
              </button>
            </div>
          </li>
        </ol>
      </div>
      <footer className="footer position-absolute mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default VanillaJS;
