import React from "react";
import Base from "../NavBar/Base";

const Tips = () => {
  return (
    <div>
      <Base />
      <span className="p-2"></span>
      <div className="text-center container container-center">
        <h1 className="text-primary">
          Top 6 Tips To Make Your Computer More Secure
        </h1>
        <span className="p-2"></span>

        <h1>1. Do you need to be connected to the internet all the time?</h1>
        <p>
          If you have a computer running for long periods of time and you don’t
          need to be connected to the internet, then it’s probably quite prudent
          to switch your internet router off. Hackers tend to prefer to exploit
          “always on” connections, and if your internet connection is more
          irregular, you’ll be less attractive to them.
        </p>
        <h1>2. Be careful what you click!</h1>
        <p>
          The problem is, that there are sites out there that are out to get
          you. They may have been affected by a worm that modifies the website
          with the intention to infect your computer with a virus. Some sites
          are there to deliberately get you. Things are a little better these
          days, but there are still plenty of cases of infected sites. Be
          careful where you’re browsing- and again make sure you’re anti-virus
          software is up to date.
        </p>
        <h1>
          3. Avoid Phishing scams - beware of suspicious emails and phone calls
        </h1>
        <p>
          Phishing scams are a constant threat - using various social
          engineering ploys, cyber-criminals will attempt to trick you into
          divulging personal information such as your login ID and password,
          banking or credit card information.
          <br />
          Phishing scams can be carried out by phone, text, or through social
          networking sites - but most commonly by email. Be suspicious of any
          official-looking email message or phone call that asks for personal or
          financial information.
        </p>
        <h1>4. Keep software up-to-date</h1>
        <p>
          Installing software updates for your operating system and programs is
          critical. Always install the latest security updates for your devices:{" "}
          <br />
          1. Turn on Automatic Updates for your operating system.
          <br />
          2. Use web browsers such as Chrome or Firefox that receive frequent,
          automatic security updates.
          <br />
          3. Make sure to keep browser plug-ins (Flash, Java, etc.) up-to-date.
        </p>
        <h1>5. Use a VPN</h1>
        <p>
          A Virtual Private Network (VPN) is an excellent way to step up your
          security, especially when browsing online. While using a VPN, all of
          your internet traffic is encrypted and tunneled through an
          intermediary server in a separate location. This masks your IP,
          replacing it with a different one, so that your ISP can no longer
          monitor your activity.
          <br />
          When it comes to choosing a provider, there are some okay free
          offerings out there, but monthly rates for paid services can be pretty
          low, even as little at $3 per month. The free ones are typically
          limited in features but can be good for getting a feel for what’s
          available. Some paid options have free trial periods for the full
          service and most offer generous money-back guarantee periods.
        </p>
        <h1>6. Don’t Trust Public Wifi</h1>
        <p>
          Did you know that much of your internet connection (web browsing and
          email) is being sent over the connection unencrypted? Anyone malicious
          in the coffee shop could be listening in and stealing your passwords.
          If you have a 3G connection then use that, but if not, you’ll need to
          secure your connection. Websites that use https (Facebook and Twitter
          for example) encrypt your data, but most websites won’t. For this,
          you’ll need to use a VPN or virtual private network. This encrypts
          your connection by connecting to a secure server in the middle
        </p>
        <p>
          <center>
            Thank you for Reading <strong>My Blog</strong>
          </center>
          <center>Keep Coding ...</center>
        </p>
        <small>November, 2020</small>
      </div>
      <footer className="footer position-relative mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Tips;
