import React from "react";
import { Link } from "react-router-dom";
import "../App.css";

const Base = () => {
  return (
    <div>
      <nav className="shadow-lg fs-1 navbar navbar-light bg-success p-1 navbar-expand-sm nav-collapse">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <h3 className="text-warning">Varunraj</h3>
          </Link>

          <div>
            <div className="navbar-nav d-flex mb-2">
              <Link className="nav-link active px-5" to="/">
                <h5 className="text-warning">Home</h5>
              </Link>

              <Link className="nav-link active px-5" to="/projects">
                <h5 className="text-warning">Projects</h5>
              </Link>

              <Link className="nav-link active px-5" to="/blogs">
                <h5 className="text-warning">Blogs</h5>
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Base;
