import React from "react";
import logo1 from "../images/HTML5.png";
import logo2 from "../images/CSS3.png";
import logo3 from "../images/JS.png";
import logo4 from "../images/react.png";

const Tech = () => {
  return (
    <div>
      <div className="row row-cols-1 row-cols-md-6 m-1 p-1 text-center d-flex justify-content-center">
        <div className="col">
          <div className="border border-success card">
            <img
              src={logo1}
              className="img-thumbnail img-fluid card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-primary">HTML5</h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div class="border border-success card">
            <img
              src={logo2}
              className="img-thumbnail img-fluid card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-primary">CSS3</h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div class="border border-success card">
            <img
              src={logo3}
              className="img-thumbnail img-fluid card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-primary">Javascript</h5>
            </div>
          </div>
        </div>
        <div className="col">
          <div class="border border-success card">
            <img
              src={logo4}
              className="img-thumbnail img-fluid card-img-top"
              alt="..."
            />
            <div className="card-body">
              <h5 className="card-title text-primary">ReactJS</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
