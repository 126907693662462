import React from "react";
import { Switch, Route } from "react-router-dom";
import Blogs from "./Blogs";
import Projects from "./NavBar/Projects";
import VanillaJS from "./projects/VanillaJS";
import ReactProjects from "./projects/ReactProjects";
import NodejsProjects from "./projects/NodejsProjects";
import MernProjects from "./projects/MernProjects";
import HtmlProjects from "./projects/HtmlProjects";
import Beginning from "./Blogs/Beginning";
import VSC from "./Blogs/VSC";
import Tips from "./Blogs/Tips";
import App from "./App";
import FirebaseProjects from "./projects/FirebaseProjects";

const Routes = () => {
  const error = () => {
    return (
      <div>
        <center>
          <h1 className="text-center text-danger">Error 404 Not Found</h1>
        </center>
      </div>
    );
  };

  return (
    <Switch>
      <Route exact path="/" component={App} />
      <Route exact path="/projects" component={Projects} />
      <Route exact path="/blogs" component={Blogs} />
      <Route exact path="/projects/1" component={HtmlProjects} />
      <Route exact path="/projects/2" component={VanillaJS} />
      <Route exact path="/projects/3" component={NodejsProjects} />
      <Route exact path="/projects/4" component={ReactProjects} />
      <Route exact path="/projects/5" component={MernProjects} />
      <Route exact path="/projects/6" component={FirebaseProjects} />
      <Route exact path="/blogs/1" component={Beginning} />
      <Route exact path="/blogs/2" component={Tips} />
      <Route exact path="/blogs/3" component={VSC} />
      <Route component={error} />
    </Switch>
  );
};

export default Routes;
