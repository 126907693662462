import React from "react";
import Base from "../NavBar/Base";

const MernProjects = () => {
  return (
    <div>
      <Base />
      <span className="p-2"></span>
      <div className="container container-center">
        <ol reversed>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">Wallpaper Store</h1>
              <small>January, 2021</small>
              <p>
                An app made using <strong>MERN Stack</strong>. Here is the Demo
                of my project.
              </p>
              <button className="btn btn-warning">
                <a href="https://www.youtube.com/watch?v=tDVFN-_VHvM">
                  Live Demo
                </a>
              </button>
            </div>
          </li>
        </ol>
      </div>
      <footer className="footer position-absolute mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default MernProjects;
