import React from "react";
import Base from "../NavBar/Base";
import "../App.css";

const ReactProjects = () => {
  return (
    <div>
      <Base />
      <span className="p-2"></span>
      <div className="container container-center">
        <ol reversed>
          <span className="p-1"></span>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">Restaurant Menu</h1>
              <small>December, 2020</small>
              <p>
                An app made using <strong>ReactJS</strong>. A Sample Menu of a
                Restaurant.
              </p>
              <button className="btn btn-warning">
                <a
                  className="link link-primary"
                  href="https://restaurantmenu-react.netlify.app/"
                >
                  Live Project
                </a>
              </button>
              <span className="p-2"></span>
              <button className="btn btn-danger">
                <a
                  className="link link-secondary"
                  href="https://github.com/varunraj30/restaurant-menu"
                >
                  View Source
                </a>
              </button>
            </div>
          </li>
          <span className="p-1"></span>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">My Favourite Games</h1>
              <small>December, 2020</small>
              <p>
                An app made using <strong>ReactJS</strong>. Do you play games?
                Here are some recommendations. Be sure to check this out.
              </p>
              <button className="btn btn-warning">
                <a
                  className="link link-primary"
                  href="https://myfavouritegames.netlify.app/"
                >
                  Live Project
                </a>
              </button>
              <span className="p-2"></span>
              <button className="btn btn-danger">
                <a
                  className="link link-secondary"
                  href="https://github.com/varunraj30/favourite-games"
                >
                  View Source
                </a>
              </button>
            </div>
          </li>
          <span className="p-1"></span>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">Emoji Interpreter</h1>
              <small>December, 2020</small>
              <p>
                An app made using <strong>ReactJS</strong>. Want to know what a
                specific emoji means? Be sure to check this out.
              </p>
              <button className="btn btn-warning">
                <a
                  className="link link-primary"
                  href="https://emoji-interpreterbyvarun.netlify.app/"
                >
                  Live Project
                </a>
              </button>
              <span className="p-2"></span>
              <button className="btn btn-danger">
                <a
                  className="link link-secondary"
                  href="https://github.com/varunraj30/emoji-interpreter"
                >
                  View Source
                </a>
              </button>
            </div>
          </li>
        </ol>
      </div>
      <footer className="footer position-relative mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default ReactProjects;
