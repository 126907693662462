const AppTheme = {
  light: {
    textColor: "#000",
    backgroundColor: "#FFF",
  },
  dark: {
    textColor: "#FFF",
    backgroundColor: "#333",
  },
};

export default AppTheme;
