import React from "react";
import Base from "./Base";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <div>
      <Base />
      <span className="p-2"></span>
      <Link className="nav-link active px-5" to="/projects/6">
        <h2 className="text-center">Projects Based on ReactJS,Firebase</h2>
      </Link>
      <span className="p-2"></span>
      <Link className="nav-link active px-5" to="/projects/5">
        <h2 className="text-center">Projects Based on MERN Stack</h2>
      </Link>
      <span className="p-2"></span>
      <Link className="nav-link active px-5" to="/projects/4">
        <h2 className="text-center">Projects Based on ReactJS</h2>
      </Link>
      <span className="p-2"></span>
      <Link className="nav-link active px-5" to="/projects/3">
        <h2 className="text-center">Projects Based on NODEJS</h2>
      </Link>
      <span className="p-2"></span>
      <Link className="nav-link active px-5" to="/projects/2">
        <h2 className="text-center">Projects Based on HTML5,CSS3,VanillaJS</h2>
      </Link>
      <span className="p-2"></span>
      <Link className="nav-link active px-5" to="/projects/1">
        <h2 className="text-center">Projects Based on HTML5,CSS3</h2>
      </Link>
      <footer className="footer position-relative mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Projects;
