import React, { useState } from "react";
import ThemeToggler from "./ThemeToggler/Components/ThemeToggler";
import HeroSection from "./ThemeToggler/Components/HeroSection";
import ThemeContext from "./ThemeToggler/Context/ThemeContext";

export default function App() {
  const themeHook = useState("dark");
  return (
    <ThemeContext.Provider value={themeHook}>
      <ThemeToggler />

      <HeroSection />
    </ThemeContext.Provider>
  );
}
