import React from "react";
import "../App.css";
import logo from "../Header.svg";
import Base from "./Base";
import Tech from "./Tech";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div>
      <Base />

      <div className="text-center">
        <img src={logo} className="w-50 h-50 rounded" alt="..." />
      </div>
      <h2 className="text-center text-success">Varunraj The Web Developer</h2>
      <h3 className="text-center text-primary p-1">Technical Skills</h3>
      <Tech />
      <h3 className="text-center text-primary">Projects</h3>
      <h4 className="text-center text-primary">
        I'd like to showcase my projects you can browse my projects from below.
      </h4>
      <div className="text-center p-2">
        <button type="button" className="btn btn-warning">
          <Link to="/projects">Browse Projects</Link>
        </button>
      </div>
      <h3 className="text-center text-primary">Blogs</h3>
      <h4 className="text-center text-primary">
        There is no great thing then sharing. Here's something I want to share.
      </h4>
      <div className="text-center p-2">
        <button type="button" className="btn btn-warning">
          <Link to="/blogs">Browse Blogs</Link>
        </button>
      </div>
      <footer className="footer mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Home;
