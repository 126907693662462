import React from "react";
import Base from "./NavBar/Base";
import { Link } from "react-router-dom";

const Blogs = () => {
  return (
    <div>
      <Base />
      <span className="p-2"></span>
      <div className="container container-center">
        <ol reversed>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">
                Visual Studio Code Keyboard Shortcuts
              </h1>
              <small>December, 2020</small>
              <p>Using this keyboard shortcuts try to make code more easier.</p>
              <button className="btn btn-warning">
                <Link className="link link-primary" to="/blogs/3">
                  Read More
                </Link>
              </button>
            </div>
          </li>
          <span className="p-1"></span>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">
                Top 6 Tips To Make Your Computer More Secure
              </h1>
              <small>November, 2020</small>
              <p>Make your computer more secure by these tips.</p>
              <button className="btn btn-warning">
                <Link className="link link-primary" to="/blogs/2">
                  Read More
                </Link>
              </button>
            </div>
          </li>
          <span className="p-1"></span>
          <li>
            <div className="container container-center">
              <h1 className="text-primary">The Beginning</h1>
              <small>November, 2020</small>
              <p>Beginning of my Blogs.</p>
              <button className="btn btn-warning">
                <Link className="link link-primary" to="/blogs/1">
                  Read More
                </Link>
              </button>
            </div>
          </li>
        </ol>
      </div>
      <footer className="footer position-relative mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Blogs;
