import React from "react";
import Base from "../NavBar/Base";

const Beginning = () => {
  return (
    <div>
      <Base />
      <span className="p-2"></span>
      <div className="text-center container container-center">
        <h1 className="text-primary">The Beginning</h1>
        <span className="p-2"></span>
        <p>
          Hello Everyone! My name is <strong>Varunraj</strong>. This is my first
          blog. This year I completed my Bachelor's Degree. Now I am trying to
          get a job in <strong>IT</strong> field. My dream is to become
          <strong> MERN Stack Developer</strong>. So I am trying my best to
          reach that goal by practicing code daily, reading documentation,
          watching online tutorials all that stuff.
          <br />I started my journey through <strong>C , C++</strong> in the
          year 2017. Then I started
          <strong> MySQL</strong> in 2018. In 2019 I started{" "}
          <strong>Python</strong> and <strong>Java</strong>. For now, I am
          trying to learn <strong>ReactJS</strong>. <br />
          I am trying not to stick to one programming language. I want to have
          taste of all other programming languages. I want to Enjoy while doing
          code :<br />
          <center>
            Thank you for Reading <strong>My First Blog</strong>
          </center>
          <center>Keep Coding ...</center>
        </p>
        <small>November, 2020</small>
      </div>
      <footer className="footer position-fixed mt-auto py-2">
        <div className="container-fluid bg-success text-white text-center py-3">
          <h5>Social Media</h5>
          <a href="https://github.com/varunraj30">
            <i
              className="fa-3x p-2
          fa fa-github"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://twitter.com/varunraj3009">
            <i
              className="fa-2x p-2 
          fa fa-twitter"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.linkedin.com/public-profile/in/varunraj-goud-0199b115a/">
            <i
              className="fa-2x p-2 
          fa fa-linkedin"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.instagram.com/varunraj30/">
            <i
              className="fa-2x p-2
          fa fa-instagram"
              aria-hidden="true"
            ></i>
          </a>
          <a href="https://www.youtube.com/channel/UCUKckpYS5a6j-FchtPJt6Sg/videos">
            <i
              className="fa-2x p-2
          fa fa-youtube"
              aria-hidden="true"
            ></i>
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Beginning;
